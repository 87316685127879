import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, Remark, Absence, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/collaborator`
const GET_USERS_URL = `${API_URL}/collaborator`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const addAvatar = (formData: any, userId: string) => {
  return axios.put(`${USER_URL}/avatar/${userId}`, formData)
}

const getCollaboratorsArray = (limit = 0): Promise<Array<User>> => {
  return axios
    .get(`${GET_USERS_URL}?enabled=true&${limit > 0 ? 'items_per_page=' + limit : ''}`)
    .then((d: AxiosResponse<any>) => {
      return d.data.data
    })
}

const getCollaboratorsCount = (): Promise<number> => {
  return axios.get(`${GET_USERS_URL}`).then((d: AxiosResponse<any>) => {
    return d.data.count
  })
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const getUser = (id: string): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(`${USER_URL}/${user._id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const addRemark = (user: User, remark: Remark): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user._id}/remark`, {content: remark.content})
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const addAbsence = (user: User, absence: Absence): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/absence/${user._id}`, absence)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const getAbsenceArray = (from: string, to: string): Promise<Array<Absence>> => {
  return axios
    .get(`${GET_USERS_URL}/absence/?from=${from}&to=${to}`)
    .then((d: AxiosResponse<any>) => {
      return d.data.data
    })
}

const deleteAbsence = (id: string, date: any): Promise<void> => {
  return axios
    .delete(`${USER_URL}/absence/${id}`, {
      data: date,
    })
    .then(() => {})
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getUsers,
  deleteUser,
  addRemark,
  deleteSelectedUsers,
  getUserById,
  getUser,
  createUser,
  updateUser,
  getCollaboratorsArray,
  addAbsence,
  getAbsenceArray,
  deleteAbsence,
  getCollaboratorsCount,
  addAvatar,
}
