import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/profile/me`
export const LOGIN_URL = `${API_URL}/auth/sign-in`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/password`
export const CHANGE_PASSWORD_URL = `${API_URL}/auth/password/:token`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    email,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function changePassword(token: string, password: string, password_repeat: string) {
  return axios.put(CHANGE_PASSWORD_URL.replace(':token', token), {
    password,
    password_repeat,
  })
}

export function getUserByToken(token: string) {
  return axios.get(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {Authorization: `Bearer ${token}`},
  })
}
