import React, {createContext, FC, useEffect, useState} from 'react'
import {WithChildren} from '../../../../../_metronic/helpers'
import {getNotificationsArray} from './_requests'
import {Notification} from './_models'

// Définir le type du contexte
type NotificationContextType = {
  notifications: Notification[]
  count: number
}

const NotificationContext = createContext<NotificationContextType>({
  notifications: [],
  count: 0,
})

const NotificationProvider: FC<WithChildren> = ({children}) => {
  const [notifications, setNotifications] = useState<Array<Notification>>([])
  const [count, setCount] = useState<number>(0)

  useEffect(() => {
    // Effectuer la requête HTTP pour récupérer les notifications
    const fetchNotifications = async () => {
      try {
        const response = await getNotificationsArray(false)
        setNotifications(response.data)
        setCount(response.count)
      } catch (error) {
        console.error('Erreur lors de la récupération des notifications :', error)
      }
    }

    fetchNotifications()
  }, [])

  return (
    <NotificationContext.Provider value={{notifications, count}}>
      {children}
    </NotificationContext.Provider>
  )
}

export {NotificationContext, NotificationProvider}
