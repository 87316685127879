import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Notification, NotificationQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const THEMATIC_URL = `${API_URL}/profile/notification`
const GET_THEMATIC_URL = `${API_URL}/profile/notification`

const getNotifications = (query: string): Promise<NotificationQueryResponse> => {
  return axios
    .get(`${GET_THEMATIC_URL}?${query}`)
    .then((d: AxiosResponse<NotificationQueryResponse>) => d.data)
}

const getNotificationsArray = (isRead?: boolean): Promise<any> => {
  return axios
    .get(
      `${GET_THEMATIC_URL}?${isRead === true ? 'read=true' : ''}${
        isRead === false ? 'read=false' : ''
      }`
    )
    .then((d: AxiosResponse<any>) => {
      return {data: d.data.data, count: d.data.count}
    })
}

const getNotificationById = (id: ID): Promise<Notification | undefined> => {
  return axios
    .get(`${THEMATIC_URL}/${id}`)
    .then((response: AxiosResponse<Response<Notification>>) => response.data)
    .then((response: Response<Notification>) => response.data)
}

const createNotification = (thematic: Notification): Promise<Notification | undefined> => {
  return axios
    .post(THEMATIC_URL, thematic)
    .then((response: AxiosResponse<Response<Notification>>) => response.data)
    .then((response: Response<Notification>) => response.data)
}

const updateNotification = (
  notification: Notification,
  params: Object
): Promise<Notification | undefined> => {
  return axios
    .put(`${THEMATIC_URL}/${notification._id}`, params)
    .then((response: AxiosResponse<Response<Notification>>) => response.data)
    .then((response: Response<Notification>) => response.data)
}

const deleteNotification = (thematicId: ID): Promise<void> => {
  return axios.delete(`${THEMATIC_URL}/${thematicId}`).then(() => {})
}

const deleteSelectedNotifications = (thematicIds: Array<ID>): Promise<void> => {
  const requests = thematicIds.map((id) => axios.delete(`${THEMATIC_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getNotifications,
  getNotificationsArray,
  deleteNotification,
  deleteSelectedNotifications,
  getNotificationById,
  createNotification,
  updateNotification,
}
