import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Quotation, QuotationQueryResponse, Row} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const QUOTATION_URL = `${API_URL}/quotation`
const GET_QUOTATION_URL = `${API_URL}/quotation`

const getQuotations = (query: string): Promise<QuotationQueryResponse> => {
  return axios
    .get(`${GET_QUOTATION_URL}?${query}`)
    .then((d: AxiosResponse<QuotationQueryResponse>) => d.data)
}

const getQuotationsArray = (): Promise<Array<Quotation>> => {
  return axios.get(`${GET_QUOTATION_URL}`).then((d: AxiosResponse<any>) => {
    return d.data.data
  })
}
const getQuotationsCount = (): Promise<number> => {
  return axios.get(`${GET_QUOTATION_URL}`).then((d: AxiosResponse<any>) => {
    return d.data.count
  })
}

const getQuotationById = (id?: any): Promise<Quotation | undefined> => {
  return axios
    .get(`${QUOTATION_URL}/${id}`)
    .then((response: AxiosResponse<Response<Quotation>>) => response.data)
    .then((response: Response<Quotation>) => response.data)
}

const createQuotation = (quotation: any): Promise<Quotation | undefined> => {
  if (!quotation.brand) {
    delete quotation.brand
  }
  return axios
    .post(QUOTATION_URL, quotation)
    .then((response: AxiosResponse<Response<Quotation>>) => response.data)
    .then((response: Response<Quotation>) => response.data)
}

const createQuotationRow = (quotation: any, row: any): Promise<Quotation | undefined> => {
  return axios
    .post(`${QUOTATION_URL}/custom_row/${quotation._id.toString()}`, row)
    .then((response: AxiosResponse<Response<Quotation>>) => response.data)
    .then((response: Response<Quotation>) => response.data)
}

const deleteQuotationRow = (row: any): Promise<void> => {
  return axios.delete(`${QUOTATION_URL}/custom_row/${row}`).then(() => {})
}

const updateQuotation = (quotation: any): Promise<Quotation | undefined> => {
  return axios
    .put(`${QUOTATION_URL}/${quotation._id}`, quotation)
    .then((response: AxiosResponse<Response<Quotation>>) => response.data)
    .then((response: Response<Quotation>) => response.data)
}


const updateStatusQuotation = (
  quotation: Quotation,
  status: string
): Promise<Quotation | undefined> => {
  return axios
    .put(`${QUOTATION_URL}/set-status/${quotation._id}`, {status})
    .then((response: AxiosResponse<Response<Quotation>>) => response.data)
    .then((response: Response<Quotation>) => response.data)
}

const deleteQuotation = (quotationId: ID): Promise<void> => {
  return axios.delete(`${QUOTATION_URL}/${quotationId}`).then(() => {})
}

const refreshQuotationRow = (quotationId: ID): Promise<void> => {
  return axios.patch(`${QUOTATION_URL}/location_row/${quotationId}`).then(() => {})
}

const deleteSelectedQuotations = (quotationIds: Array<ID>): Promise<void> => {
  const requests = quotationIds.map((id) => axios.delete(`${QUOTATION_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const downloadQuotation = (id: any): Promise<Blob> => {
  return axios
  .get(`${QUOTATION_URL}/export/${id}`, {responseType: 'blob'})
  .then((response: AxiosResponse<Blob>) => response.data)
}

const sendQuotation = (id: any): Promise<Blob> => {
  return axios
  .post(`${QUOTATION_URL}/export/${id}`, {})
  .then((response: AxiosResponse<any>) => response.data)
}

const updateQuoationRowAmount = (rowId: any, amount: number): Promise<any | undefined> => {
  return axios
    .put(`${QUOTATION_URL}/location_row`, {
      rowId,
      amount,
    })
    .then((response: AxiosResponse<Response<Quotation>>) => response.data)
    .then((response: Response<Quotation>) => response.data)
}

export {
  refreshQuotationRow,
  updateQuoationRowAmount,
  getQuotations,
  getQuotationsArray,
  getQuotationsCount,
  deleteQuotation,
  deleteSelectedQuotations,
  getQuotationById,
  createQuotation,
  updateQuotation,
  createQuotationRow,
  deleteQuotationRow,
  updateStatusQuotation,
  downloadQuotation,
  sendQuotation
}
