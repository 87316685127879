/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useContext} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl, defaultAlerts, defaultLogs} from '../../../helpers'
import {NotificationContext} from '../../../../app/modules/kamelia/notification/core/ListViewProvider'
import moment from 'moment'

const HeaderNotificationsMenuCustom: FC = () => {
  const {notifications} = useContext(NotificationContext)

  const getIconByType = (type: string): any => {
    switch (type) {
      case 'badge-expired':
        return {
          state: 'warning',
          icon: 'icons/duotune/technology/teh008.svg',
        }
      case 'pid-expired':
        return {
          state: 'warning',
          icon: 'icons/duotune/general/gen044.svg',
        }
      case 'absence':
        return {
          state: 'danger',
          icon: 'icons/duotune/general/gen044.svg',
        }
      default:
        return {
          state: 'default',
          icon: 'icons/duotune/general/gen044.svg',
        }
    }
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
          Notifications{' '}
          <span className='fs-8 opacity-75 ps-3'>{notifications.length} notifications</span>
        </h3>
      </div>

      <div className='tab-content'>
        <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {notifications.slice(0, 10).map((notification, index) => (
              <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                <div className='d-flex zzalign-items-center'>
                  <div className='symbol symbol-35px me-4'>
                    <span
                      className={clsx(
                        'symbol-label',
                        `bg-light-${getIconByType(notification.type || '').state}`
                      )}
                    >
                      {' '}
                      <KTSVG
                        path={`/media/${getIconByType(notification.type || '').icon}`}
                        className={`svg-icon-2 svg-icon-${
                          getIconByType(notification.type || '').state
                        }`}
                      />
                    </span>
                  </div>

                  <div className='mb-0 me-2'>
                    <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                      {notification.title}
                    </a>
                    <div className='text-gray-400 fs-7'>{notification.body}</div>
                  </div>
                </div>

                <span className='badge badge-light fs-8'>
                  {moment(notification.date).format('DD/MM/YYYY')}
                </span>
              </div>
            ))}
          </div>

          <div className='py-3 text-center border-top'>
            <Link
              to='/admin/notification'
              className='btn btn-color-gray-600 btn-active-color-primary'
            >
              voir toutes
              <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-5' />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export {HeaderNotificationsMenuCustom}
