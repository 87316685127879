import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const REPORT_URL = `${API_URL}/report`

const getCAdata = (from: string, to: string): Promise<any> => {
  return axios
    .get(`${REPORT_URL}/turnover?from=${from}&to=${to}&group=date`)
    .then((d: AxiosResponse<any>) => {
      return d.data.data
    })
}

const getForecastData = (from: string, to: string): Promise<any> => {
  return axios
    .get(`${REPORT_URL}/forecast?from=${from}&to=${to}&group=date`)
    .then((d: AxiosResponse<any>) => {
      return d.data.data
    })
}

const getCAClientdata = (from: string, to: string): Promise<any> => {
  return axios
    .get(`${REPORT_URL}/turnover?from=${from}&to=${to}&group=client`)
    .then((d: AxiosResponse<any>) => {
      return d.data.data
    })
}
export {getCAdata, getCAClientdata, getForecastData}
