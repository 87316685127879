/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  const menus = []
  const defaultCategories = {
    label: 'global',
    items: [
      {
        to: '/dashboard',
        icon: '/media/icons/duotune/art/art002.svg',
        title: 'Accueil',
      },
    ],
  }
  menus.push({
    role: 'admin',
    categories: [
      {
        label: 'Utilisateurs',
        items: [
          {
            to: '/admin/users',
            icon: '/media/icons/duotune/communication/com006.svg',
            title: 'Utilisateurs',
          },
          {
            to: '/admin/collaborators',
            icon: '/media/icons/duotune/communication/com014.svg',
            title: 'Promoteurs',
          },
          {
            to: '/admin/client',
            icon: '/media/icons/duotune/communication/com013.svg',
            title: 'Clients',
          },
          {
            to: '/admin/activity',
            icon: '/media/icons/duotune/communication/com013.svg',
            title: 'Journal de log',
          },
        ],
      },
      {
        label: 'Configuration',
        items: [
          {
            to: '/admin/location',
            icon: '/media/icons/duotune/general/gen018.svg',
            title: 'Lieux',
          },
          {
            to: '/admin/thematic',
            icon: '/media/icons/duotune/abstract/abs027.svg',
            title: 'Thématique',
          },
          {
            to: '/admin/astreinte',
            icon: '/media/icons/duotune/communication/com011.svg',
            title: 'Astreinte',
          },
          {
            to: '/admin/entity',
            icon: '/media/icons/duotune/communication/com011.svg',
            title: 'Entité',
          },
        ],
      },
      {
        label: 'Finance',
        items: [
          {
            to: '/admin/invoice',
            icon: '/media/icons/duotune/finance/fin008.svg',
            title: 'Factures',
          },
          {
            to: '/admin/quotation',
            icon: '/media/icons/duotune/finance/fin006.svg',
            title: 'Devis',
          },
          {
            to: '/admin/contract',
            icon: '/media/icons/duotune/finance/fin002.svg',
            title: 'Contrat',
          },
        ],
      },
      {
        label: 'Planning',
        items: [
          {
            to: '/admin/planning',
            icon: '/media/icons/duotune/files/fil002.svg',
            title: 'Gestion des plannings',
          },
        ],
      },
    ],
  })
  menus.push({
    role: 'technical',
    categories: [
      defaultCategories,
      ...(menus.find((menu) => menu.role === 'admin')?.categories ?? []),
    ],
  })
  menus.push({
    role: 'collaborator',
    categories: [
      defaultCategories,
      {
        label: 'Planning',
        items: [
          {
            to: '/collaborator/planning',
            icon: '/media/icons/duotune/files/fil002.svg',
            title: 'Gestion des plannings',
          },
          {
            to: '/admin/astreinte',
            icon: '/media/icons/duotune/communication/com011.svg',
            title: 'Astreinte',
          },
        ],
      },
    ],
  })
  menus.push({
    role: 'client',
    categories: [
      defaultCategories,
      {
        label: 'Planning',
        items: [
          {
            to: '/client/planning',
            icon: '/media/icons/duotune/files/fil002.svg',
            title: 'Gestion des plannings',
          },
        ],
      },
    ],
  })
  menus.push({
    role: 'supervisor',
    categories: [
      defaultCategories,
      {
        label: 'Planning',
        items: [
          {
            to: '/admin/planning',
            icon: '/media/icons/duotune/files/fil002.svg',
            title: 'Gestion des plannings',
          },
        ],
      },
    ],
  })

  return (
    <>
      {menus
        ?.find((menu) => menu?.role === currentUser?.role)
        ?.categories?.map((category) => {
          return (
            <>
              {category.label !== 'global' ? (
                <div className='menu-item'>
                  <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                      {category.label}
                    </span>
                  </div>
                </div>
              ) : (
                ''
              )}
              {category?.items.map((item: any) => {
                return <SidebarMenuItem {...item} />
              })}
            </>
          )
        })}
    </>
  )
}

export {SidebarMenuMain}
