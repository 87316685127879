import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {User, Remark, UsersQueryResponse} from './_models'
import {Quotation} from '../../../quotation-management/quotation-list/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/client`
const GET_USERS_URL = `${API_URL}/client`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?enabled=true&${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const getUser = (id: string): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const getClientArrau = (limit = 10000): Promise<Array<User>> => {
  return axios.get(`${USER_URL}?items_per_page=${limit}`).then((d: AxiosResponse<any>) => {
    return d.data.data
  })
}

const getClientCount = (): Promise<number> => {
  return axios.get(`${USER_URL}`).then((d: AxiosResponse<any>) => {
    return d.data.count
  })
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(`${USER_URL}/${user._id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const addRemark = (user: User, remark: Remark): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user._id}/remark`, {content: remark.content})
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getUsers,
  deleteUser,
  addRemark,
  deleteSelectedUsers,
  getUserById,
  getUser,
  createUser,
  updateUser,
  getClientArrau,
  getClientCount,
}
