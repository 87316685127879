/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'
import {KTSVG} from '../../../../helpers'
import {useNavigate} from 'react-router-dom'

type Props = {
  className: string
}

const rows: Array<{description: string; link: string}> = [
  {description: 'Gestion des devis', link: '/admin/quotation/overview'},
  {description: 'Gestion Collaborateurs', link: '/admin/collaborators'},
  {description: 'Gestion clients', link: '/admin/client'},
]

const ListsWidget26 = ({className}: Props) => {
  const navigate = useNavigate()

  const handleButtonClick = (link: string) => {
    navigate(link)
  }

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <h3 className='card-title text-gray-800 fw-bold'>Liens utils</h3>
        <div className='card-toolbar'></div>
      </div>
      <div className='card-body pt-5'>
        {rows.map((row, index) => (
          <Fragment key={`lw26-rows-${index}`}>
            <div className='d-flex flex-stack'>
              <a href={row.link} className='text-primary fw-semibold fs-6 me-2'>
                {row.description}
              </a>
              <button
                type='button'
                className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                onClick={() => handleButtonClick(row.link)}
              >
                <KTSVG path='media/icons/duotune/arrows/arr095.svg' className='svg-icon-2' />
              </button>
            </div>
            {rows.length - 1 > index && <div className='separator separator-dashed my-3' />}
          </Fragment>
        ))}
      </div>
    </div>
  )
}
export {ListsWidget26}
