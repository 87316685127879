/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {getCollaboratorsArray} from '../../../../app/modules/kamelia/collaborator-management/users-list/core/_requests'
import {User} from '../../../../app/modules/kamelia/collaborator-management/users-list/core/_models'
import Gravatar from '../../../../service/Gravatar'

type Props = {
  className: string
}

const ListsWidget9: React.FC<Props> = ({className}) => {
  const [collaborators, setCollaborators] = useState<Array<User>>()
  useEffect(() => {
    ;(async () => {
      setCollaborators(await getCollaboratorsArray(5))
    })()
  }, [])

  return (
    <div className={clsx('card', className)}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-3'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder text-dark fs-3'>Collaborateurs</span>
          <span className='text-gray-400 mt-2 fw-bold fs-6'>Dernières connexions</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {collaborators?.map((collaborator) => {
          return (
            <div className='d-flex mb-7'>
              {/*begin::Symbol*/}
              <div className='symbol symbol-60px symbol-2by3 flex-shrink-0 me-4'>
                <img
                  style={{width: 'auto'}}
                  src={
                    !collaborator?.avatar
                      ? Gravatar.getUrl(collaborator?.email)
                      : `https://api.kaelhios.ch/static/avatars/${collaborator?.avatar}`
                  }
                  className='mw-100'
                  alt=''
                />
              </div>
              <div className='d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1'>
                <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3'>
                  <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                    {collaborator.firstname} {collaborator.lastname}
                  </a>
                  <span className='text-gray-400 fw-bold fs-7'>
                    <a
                      href={`admin/collaborator/${collaborator?._id?.toString()}/overview`}
                      className='text-primary fw-bold'
                    >
                      Voir la fiche
                    </a>
                  </span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export {ListsWidget9}
