/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  TablesWidget5,
  CardsWidget1,
  ChartsWidget1,
  ListsWidget26,
  ListsWidget9,
  ChartsWidget9,
  ChartsWidget10,
} from '../../../_metronic/partials/widgets'
import {
  getCAdata,
  getCAClientdata,
  getForecastData,
} from '../../modules/kamelia/dashboard/core/_requests'
import moment from 'moment'

import {getCollaboratorsCount} from '../../modules/kamelia/collaborator-management/users-list/core/_requests'
import {getClientCount} from '../../modules/kamelia/client-management/users-list/core/_requests'
import {getQuotationsCount} from '../../modules/kamelia/quotation-management/quotation-list/core/_requests'
import {useAuth} from '../../modules/auth'

const DashboardPage: FC = () => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const [collaboratorCount, setCollaboratorCount] = useState<number>(0)
  const [clientCount, setClientCount] = useState<number>(0)
  const [quotationCount, setQuotationCount] = useState<number>(0)
  const [caData, setCaData] = useState<any>(null)
  const [caDataClient, setCaDataClient] = useState<any>(null)
  const [caDataForecast, setCaDataForecast] = useState<any>(null)

  useEffect(() => {
    ;(async () => {
      if (currentUser?.role === 'collaborator') {
        navigate('/collaborator/planning/overview')
      }
      if (currentUser?.role === 'client') {
        navigate('/client/planning/overview')
      }
      setCollaboratorCount(await getCollaboratorsCount())
      setClientCount(await getClientCount())
      setQuotationCount(await getQuotationsCount())
      setCaData(
        await getCAdata(
          moment().subtract(1, 'year').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        )
      )
      setCaDataForecast(
        await getForecastData(
          moment().subtract(1, 'year').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        )
      )
      setCaDataClient(
        await getCAClientdata(
          moment().subtract(1, 'year').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        )
      )
    })()
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-xxl-9'>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-xxl-4'>
              <CardsWidget1
                number={collaboratorCount}
                className='h-md-10 mb-5 mb-xl-10'
                description='Nb Promoteurs'
                color='#F1416C'
                img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              />
            </div>
            <div className='col-xxl-4'>
              <CardsWidget1
                number={clientCount}
                className='h-md-10 mb-5 mb-xl-10'
                description='Nb Clients'
                color='#6FB1D4'
                img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              />
            </div>
            <div className='col-xxl-4'>
              <CardsWidget1
                number={quotationCount}
                className='h-md-10 mb-5 mb-xl-10'
                description='Nb Devis'
                color='#A4DDBF'
                img={toAbsoluteUrl('/media/patterns/vector-2.png')}
              />
            </div>

            <div className='col-xxl-12'>
              {!caData ? '' : <ChartsWidget1 className='' data={caData} />}
            </div>
            <div className='col-xxl-6'>
              {!caDataClient ? '' : <ChartsWidget9 className='' data={caDataClient} />}
            </div>
            <div className='col-xxl-6'>
              {!caDataForecast ? '' : <ChartsWidget10 className='' data={caDataForecast} />}
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </div>
        <div className='col-xxl-3'>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <ListsWidget26 className='h-lg-10' />
          </div>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <ListsWidget9 className='h-lg-10' />
          </div>
        </div>
      </div>

      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-12'>
          {/* <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' /> */}
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Accueil</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
