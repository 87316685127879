import {lazy, FC, Suspense} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import {MasterLayout} from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper';
import {MenuTestPage} from '../pages/MenuTestPage';
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils';
import {WithChildren} from '../../_metronic/helpers';
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper';

const PrivateRoutes = () => {
    const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
    const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
    const UserPage = lazy(() => import('../modules/users/UserPage'));
    const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
    const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));
    const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'));

    const UserRoute = lazy(() => import('../modules/kamelia/user-management/UsersPage'));
    const UserViewRoute = lazy(() => import('../modules/kamelia/user-view/AccountPage'));
    const Planning = lazy(() => import('../modules/kamelia/planning/Client'));
    const PlanningClient = lazy(() => import('../modules/kamelia/planningClient/Client'));
    const PlanningCollaborator = lazy(() => import('../modules/kamelia/planningCollaborator/Client'));
    const ClientRoute = lazy(() => import('../modules/kamelia/client-management/UsersPage'));
    const LocationRoute = lazy(() => import('../modules/kamelia/location-management/LocationPage'));
    const ContractRoute = lazy(() => import('../modules/kamelia/contract/ContractPage'));
    const ThematicRoute = lazy(() => import('../modules/kamelia/thematic-management/ThematicPage'));
    const ActivityRoute = lazy(() => import('../modules/kamelia/activity-management/ActivityPage'));
    const AstreinteRoute = lazy(() => import('../modules/kamelia/astreinte-management/AstreintePage'));
    const EntityRoute = lazy(() => import('../modules/kamelia/entity-management/EntityPage'));
    const VoiceRoute = lazy(() => import('../modules/kamelia/voice-management/VoicePage'));
    const NotificationRoute = lazy(
        () => import('../modules/kamelia/notification-management/NotificationPage')
    );
    const QuotationRoute = lazy(() => import('../modules/kamelia/quotation-management/QuotationPage'));
    const ClientViewRoute = lazy(() => import('../modules/kamelia/client-view/AccountPage'));
    const CollaboratorRoute = lazy(
        () => import('../modules/kamelia/collaborator-management/UsersPage')
    );
    const CollaboratorViewRoute = lazy(
        () => import('../modules/kamelia/collaborator-view/AccountPage')
    );

    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path="auth/*" element={<Navigate to="/dashboard"/>}/>

                {/* Kamelia page */}
                <Route
                    path="client/planning/*"
                    element={
                        <SuspensedView>
                            <PlanningClient/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="collaborator/planning/*"
                    element={
                        <SuspensedView>
                            <PlanningCollaborator/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="admin/planning/*"
                    element={
                        <SuspensedView>
                            <Planning/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="admin/users"
                    element={
                        <SuspensedView>
                            <UserRoute/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="admin/profile/:id/*"
                    element={
                        <SuspensedView>
                            <UserViewRoute/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="admin/location"
                    element={
                        <SuspensedView>
                            <LocationRoute/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="admin/contract"
                    element={
                        <SuspensedView>
                            <ContractRoute/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="admin/thematic"
                    element={
                        <SuspensedView>
                            <ThematicRoute/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="admin/invoice"
                    element={
                        <SuspensedView>
                            <VoiceRoute/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="admin/notification"
                    element={
                        <SuspensedView>
                            <NotificationRoute/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="admin/quotation/*"
                    element={
                        <SuspensedView>
                            <QuotationRoute/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="admin/collaborators"
                    element={
                        <SuspensedView>
                            <CollaboratorRoute/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="admin/client"
                    element={
                        <SuspensedView>
                            <ClientRoute/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="admin/activity"
                    element={
                        <SuspensedView>
                            <ActivityRoute/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="admin/astreinte/:date"
                    element={
                        <SuspensedView>
                            <AstreinteRoute/>
                        </SuspensedView>
                    }
                />

                <Route
                    path="admin/astreinte"
                    element={
                        <SuspensedView>
                            <AstreinteRoute/>
                        </SuspensedView>
                    }
                />

                <Route
                    path="admin/entity"
                    element={
                        <SuspensedView>
                            <EntityRoute/>
                        </SuspensedView>
                    }
                />

                <Route
                    path="admin/collaborator/:id/*"
                    element={
                        <SuspensedView>
                            <CollaboratorViewRoute/>
                        </SuspensedView>
                    }
                />

                <Route
                    path="admin/client/:id/*"
                    element={
                        <SuspensedView>
                            <ClientViewRoute/>
                        </SuspensedView>
                    }
                />

                {/* Pages */}
                <Route path="dashboard" element={<DashboardWrapper/>}/>
                <Route path="builder" element={<BuilderPageWrapper/>}/>
                <Route path="menu-test" element={<MenuTestPage/>}/>
                {/* Lazy Modules */}
                <Route
                    path="crafted/pages/profile/*"
                    element={
                        <SuspensedView>
                            <ProfilePage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="crafted/pages/wizards/*"
                    element={
                        <SuspensedView>
                            <WizardsPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="crafted/widgets/*"
                    element={
                        <SuspensedView>
                            <WidgetsPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="crafted/account/*"
                    element={
                        <SuspensedView>
                            <AccountPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="apps/chat/*"
                    element={
                        <SuspensedView>
                            <ChatPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="apps/user-management/*"
                    element={
                        <SuspensedView>
                            <UsersPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path="apps/users/*"
                    element={
                        <SuspensedView>
                            <UserPage/>
                        </SuspensedView>
                    }
                />
                {/* Page Not Found */}
                <Route path="*" element={<Navigate to="/error/404"/>}/>
            </Route>
        </Routes>
    );
};

const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--kt-primary');
    TopBarProgress.config({
        barColors: {
            '0': baseColor
        },
        barThickness: 1,
        shadowBlur: 5
    });
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>;
};

export {PrivateRoutes};
